/* ------------------------ */
/*boje za artikle*/

.bianco {
    background-color: #f9f6f8;
}

.visone {
    background-color: #ca9e87;
}

.safari {
    background-color: #936653;
}

.beige {
    background-color: #e6bca8;
}

.daino {
    background-color: #906652;
}

.glace {
    background-color: #84614d;
}

.antilope {
    background-color: #694a40;
}

.bronzo {
    background-color: #6c4e44;
}

.gray {
    background-color: #6e6e6e;
}

.darkblue {
    background-color: #392e48;
}

.anthracite {
    background-color: #121212;
}

.nero {
    background-color: #000000;
}

.playa {
    background-color: #ac9085;
}

.granat {
    background-color: #04063a;
}

.forest {
    background-color: #243e24;
}

.red {
    background-color: red;
}

.black {
    background-color: #000;
}

.ecru {
    background-color: #ecccb7;
}

.tabaco {
    background-color: #ecccb7;
}

.natural {
    background-color: #f2d8c8;
}

.grigio {
    background-color: #252525;
}

.pink {
    background-color: pink;
}

.white {
    background-color: white;
}

/* BASIC SETUP */


/* ------------------------ */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    background-color: #fff;
    color: #555;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 10px;
    text-rendering: optimizeLegibility;
}

body {
    font-size: 2 rem;
}

.row {
    width: 1200px;
    margin: 0 auto;
}

section {
    padding: 8rem 0;
}
button{
    cursor: pointer;
}
.btn{
    cursor: pointer;
}


/* ------------------------ */


/* Heading styles */


/* ------------------------ */

.box {
    padding: 1%;
    font-size: 20px;
}

.celarfix::after {
    content: "";
    clear: both;
    display: block;
}

h1,
h2,
h3 {
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
}


h1 {
    color: #fff;
    margin-bottom: 2rem;
    margin-top: 0;
    font-size: 3rem;
    word-spacing: 4px;
    font-weight: 500;
}

h2::after {
    height: 2px;
    background-color: yellow;
    display: block;
    width: 8rem;
    margin: 3rem auto;
    content: "";
}

h2 {
    font-size: 3.5rem;
    word-spacing: 2rem;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

h3 {
    margin-bottom: 1.5rem;
    font-size: 2.2rem;
}
.artikal p{
    font-weight: 500;
}


/* ------------------------ */


/* HEADER */


/* ------------------------ */

header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.774), rgba(0, 0, 0, 0.445)), url(img/hero.jpg);
    height: 80vh;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

header h1 span {
    color: #fff;
    font-size: 9rem;
    font-weight: 100;
}

.hero-text-box {
    width: 1140px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}

.logo {
    margin-top: 20px;
    height: 130px;
    width: auto;
    float: left;
    
}

.main-nav {
    float: right;
    margin-top: 5rem;
    list-style: none;
    
  
}
.main-navfixed{
    float: none;
    position: fixed;
    background: linear-gradient(to right, #b62c2c 15%, #e0d90a);;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    margin-top: 0;
    z-index: 1000;
    width: 1200px;
}


.main-nav li {
    display: inline-block;
    margin-left: 4rem;
}

.main-nav li :first-child {
    margin-left: 0;
}

.main-nav li a:link,
.main-nav li a:visited {
    color: #fff;
    text-transform: uppercase;
    font-size: 2.4rem;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    padding-bottom: 8px;
}

.main-nav li a:hover {
    border-bottom: 2px solid red;
    transition: 0.5s;
}

.main-nav li a:active {
    border-bottom: 2px solid yellow;
    transition: 0.5s;
}

.activelink {
    border-bottom: 2px solid yellow;
    transition: 0.5s;
}


/* Style The Dropdown Button */
.dropbtn {
    background-color: transparent;
    color: white;
    padding: 16px;
    font-size: 20px;
    border: none;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: rgba(64, 64, 64, 0.768);
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #eee70fa3;
    text-decoration: underline;

}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    color: yellow;
}


/* ------------------------ */


/* Buttonn styles */


/* ------------------------ */

.btn {
    display: inline-block;
    padding: 1rem 3rem;
    text-decoration: none;
    font-size: 2rem;
    border-radius: 200px;
    color: #fff;
    margin-top: 2rem;
    transition: all 400ms;
    background-color: red;
}

.btn:active,
.btn:hover {
    background-color: yellow;
    color: red !important;
    transition: 0.5s;
}

.btn-orange:link,
.btn-orange:visited {
    color: #fff;
    margin-right: 15px;
    border: 1px solid red;
    background-color: red;
}

.btn-orange:active,
.btn-orange:hover {
    color: #fff;
    border: 1px solid red;
    background-color: red;
    transition: 1s;
}

.btn-outline:link,
.btn-outline:visited {
    color: red;
    border: 1px solid red;
}

.btn-outline:active,
.btn-outline:hover {
    color: #fff;
    border: 1px solid red;
}


/*Paragraph styles styles*/

.text-section-heading {
    line-height: 2.9rem;
    width: 70%;
    text-align: center;
    margin: auto;
    font-size: 3rem;
}


.box p {
    font-size: 2rem;
    line-height: 2.9rem;
    text-decoration: none;
}

.box a {
    text-decoration: none;
    color: gray;
    font-size: 1.8rem;
}

.box a:hover {

    color: white;
    font-size: 2.5rem;
    background-color: black;
    border-radius: 20px;
    transition: 1s;
    padding: 10px;
}




.tekstonama {
    width: 80%;
    text-align: center;
    margin: auto;
    cursor: pointer;
    font-size: 3rem;
    line-height: 2.9rem;
    width: auto;
}

.galerija {

    height: 500px;
    width: 100%;
    background-color: #000;
    border-radius: 20px;

}

.galerija img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}




.kontaktmreze {
    display: flex;
    justify-content: center;
}


.slikaonama {
    display: flex;
    justify-content: center;
}


/* ------------------------ */


/* Icon styles */


/* ------------------------ */

.icon-big {
    font-size: 6.5rem;
    display: block;
    color: red;
    margin-bottom: 1rem;
}

.icon-medium {
    font-size: 4.5rem;
    display: block;
    color: gray;
    margin-bottom: 1rem;
}

.icon-small {
    color: yellow;
    display: inline-block;
    width: 30px;
    text-align: center;
    font-size: 2.4rem;
    line-height: 2.4rem;
    vertical-align: middle;
    margin-top: -5px;
}

.section-features .text-section-heading {
    margin-bottom: 3rem;
}


/* -------------slike home strana-------- */

.section-recomendation-meals {
    padding: 0;
}

.meals-showcase {
    list-style-type: none;
    width: 100%;
    margin: 0;
}

.meals-showcase li {
    list-style: none;
    float: left;
    width: 25%;
    border: 4px solid red;
    border-radius: 10px;
}

.meals-showcase li figure {
    width: 100%;
    overflow: hidden;
    margin: 0;
    background-color: #000;
}

.meals-showcase li figure img {
    width: 100%;
    height: 20rem;
    transform: scale(1.15);
    opacity: 0.7;
}

.meals-showcase li figure img:hover {
    transition: 0.5s, opacity 0.5s;
    transform: scale(1.03);
    opacity: 1;
}

.overlay {
    position: absolute;
    height: 40px;
    width: 250px;
    opacity: 0;
    transition: .5s ease;
    background-color: #00000099;
    z-index: 5;
    border-radius: 10px;
}

.meals-showcase li figure:hover .overlay {
    opacity: 1;
}

.text {
    color: rgb(255, 255, 255);
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}


/*search ----------------------------------------------------------------*/

.searchK {
    width: 100%;
    margin-left: 43%
}




.search-box {
    justify-content: center;
    border: solid 5px black;
    display: inline-block;
    position: relative;
    border-radius: 50px;

}

.search-box input[type="text"] {
    font-family: Raleway, sans-serif;
    font-size: 20px;
    font-weight: bold;
    width: 50px;
    height: 50px;
    padding: 5px 40px 5px 10px;
    border: none;
    box-sizing: border-box;
    border-radius: 50px;
    transition: width 800ms cubic-bezier(0.5, -0.5, 0.5, 0.5) 600ms;
}

.search-box input[type="text"]:focus {
    outline: none;
}

.search-box input[type="text"]:focus,
.search-box input[type="text"]:not(:placeholder-shown) {
    width: 300px;
    transition: width 800ms cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

.search-box input[type="text"]:focus+span,
.search-box input[type="text"]:not(:placeholder-shown)+span {
    bottom: 13px;
    right: 10px;
    transition: bottom 300ms ease-out 800ms, right 300ms ease-out 800ms;
}

.search-box input[type="text"]:focus+span:after,
.search-box input[type="text"]:not(:placeholder-shown)+span:after {
    top: 0;
    right: 10px;
    opacity: 1;
    transition: top 300ms ease-out 1100ms, right 300ms ease-out 1100ms, opacity 300ms ease 1100ms;
}

.search-box span {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -13px;
    right: -15px;
    transition: bottom 300ms ease-out 300ms, right 300ms ease-out 300ms;
}

.search-box span:before,
.search-box span:after {
    content: '';
    height: 25px;
    border-left: solid 5px black;
    position: absolute;
    transform: rotate(-45deg);
}

.search-box span:after {
    transform: rotate(45deg);
    opacity: 0;
    top: -20px;
    right: -10px;
    transition: top 300ms ease-out, right 300ms ease-out, opacity 300ms ease-out;
}




/*section-plans*/

section {
    background-color: #f4f4f4;
}

#button{
    display: inline-block;
    background-color: red;
    width: 150px;
    height: 50px;
    line-height: 30px;
    text-align: center;
    border-radius: 40px;
    position: fixed;
    bottom: 50px;
    right: 10%;
    transition: background-color .3s;
    opacity: 0;
    z-index: 1000;
}
#button{
    font-size: 20px;
    color: white;
}
#button:hover{
    background-color: yellow;
    color: red;
    border: red solid 1px;
    transition: 1s;
}


.plan-box {
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    margin-left: 5%;
}

.plan-box div {
    padding: 1.5rem;
    box-shadow: 1px 2px 4px #ddd;
    border-bottom: 1px solid #e8e8e8;
}

.opisartikal {
    font-size: 2rem;
}

.velicinedostupne {
    display: flex;
    justify-content: center;
    width: auto !important;

}

.odabirvelicine {
    width: 70%;
    height: 60%;

    display: flex;
    justify-content: center;
}

.vel {
    border-radius: 10%;
    width: 20%;
    height: 15%;
    padding: 0.9rem;


}

.vel :hover {
    background-color: yellow;
    transition: 1s;
    cursor: pointer;
}



.velDodato {
    color: red !important;
    font-weight: bold;
}

.vel p {
    font-size: 2.3rem;
    text-align: center;

}

.odabirboje {
    height: 200px;
    justify-content: center;

}

.odabirboje .boja {
    border-radius: 50%;
    width: 80px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}



.bojaDodato {
    border: 7px solid rgb(255, 242, 0);
}

.odabirkolicine {
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
}

.odabirkolicine button {
    border-radius: 50%;
    padding: 0.8rem;
}

.odabirkolicine form input {
    width: 40%;
    padding: 1rem;
    border-radius: 40%;
}

.porukakorpa {
    margin-top: 1px;
    height: 90%;

}

.porukakorpa p {
    text-align: center;
    align-items: center;
    padding-top: 6px;
    font-size: 30px;
    font-weight: 900;
}

.korpaArtikal:hover {
    background-image: url(./img/kanta.png);
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.2;
    cursor: pointer;
    border-radius: 30%;

}

.kolicinaiporuka {
    height: 190px;
}



.plan-box div:last-child {
    text-align: center;

}


.divslika {
    display: flex;
    justify-content: center;
    height: 420px;


}

.slikaartikal {
    margin-top: 5%;
    margin-left: 20%;
    width: 80%;
    height: 370px;
    border-radius: 20px;


}

.slikauvecanamodal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.slikauvecana {
    display: flex;
    justify-content: center;
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 5px;
    border: 1px solid #888;
    width: 50%;
    /* Could be more or less, depending on screen size */
    height: 70%;
    border-radius: 20%;
}

.modalporuka {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.porukica {
    display: flex;
    justify-content: center;
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 10px;
    border: 1px solid #888;
    width: 500px;
    /* Could be more or less, depending on screen size */
    height: 300px;
    border-radius: 20%;
    font-size: 3rem;
    align-items: center;
    justify-content: center;
}

.modalporuka1 {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.porukica1 {
    display: flex;
    justify-content: center;
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 10px;
    border: 1px solid #888;
    width: 500px;
    /* Could be more or less, depending on screen size */
    height: 300px;
    border-radius: 20%;
    font-size: 3rem;
    align-items: center;
    justify-content: center;
}


/* ------------------------ */


/* footer*/


/* ------------------------ */

footer {
    background-color: #333;
    padding: 5rem;
}

.footer-nav {
    float: left;
    list-style: none;
}

.social-icons {
    list-style: none;
    float: right;
}

.footer-nav li,
.social-icons li {
    display: inline-block;
    margin-right: 2rem;
}

.footer-nav li:last-child,
.social-icons li:last-child {
    margin-right: 0;
}

.footer-nav li a:link,
.footer-nav li a:visited,
.social-icons li a:link,
.social-icons li a:visited {
    text-decoration: none;
    border: 0;
    transition: 2s;
    color: #888;
    font-size: 30px;
}

.footer-nav li a:active,
.footer-nav li a:hover {
    color: #ddd;
    border-bottom: 1px solid #555;
}

footer p {
    color: #888;
    margin-top: 3rem;
    text-align: center;
    font-size: 20px;
}

.instagram:hover,
.instagram:active {
    font-size: 3.5rem;
    color: #c32aa3;
    transition: 0.5s;
    transform: rotate(30deg);
}

.facebook:hover,
.facebook:active {
    font-size: 3.5rem;
    color: #3b5998;
    transition: 0.5s;
    transform: rotate(30deg);
}

.twitter:hover,
.twitter:active {
    font-size: 3.5rem;
    color: #1da1f2;
    transition: 0.5s;
    transform: rotate(30deg);
}

.linkedin:hover,
.linkedin:active {
    font-size: 3.5rem;
    color: #007bb5;
    transition: 0.5s;
    transform: rotate(30deg);
}

.gmail:hover,
.gmail:active {
    font-size: 3.5rem;
    color: #ff0000;
    transition: 0.5s;
    transform: rotate(30deg);
}

.tiktok:hover,
.tiktok:active {
    font-size: 3.5rem;
    color: #000000;
    transition: 0.5s;
    transform: rotate(30deg);
}



.hidden {
    display: none !important;
}


/* =================================================================

*/

.modalkorpa {

    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.modalkorpa .kontent {
    display: flex;
    justify-content: center;
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 10px;
    border: 1px solid #888;
    width: 60%;
    /* Could be more or less, depending on screen size */
}

.main_container {
    width: 100%;
    height: 625px;

    background: linear-gradient(to right, #b62c2c 15%, #e0d90a);

}

.right_contaier {
    width:800px ;

    height: 490px;

    margin: 50px;

    background-color: hsl(220deg 27% 98%);
    float: right;
    border-radius: 10% 0% 0% 10% / 45% 0% 0% 54%;

}

.left_container {
    width: 200px;
    float: left;
    height: 400px;

    text-align: center;
    justify-content: center;
    font-family: sans-serif;
    color: #ffffff;
    margin-top: 30px;
    margin-left: 135px;


}

.korpaprikupljeno form input {

    width: 50px !important;
    text-align: center;

}

.korpaprikupljeno li {
    border: 1px solid white !important;
    padding: 10px;
}

.korpaprikupljeno {
    font-size: 2rem !important;
    ;
}

.modalkorpa i.fa-solid.fa-rocket.fa-flip-horizontal {
    transform: rotate(-45deg);
    font-size: 87px;
}

.h1 {
    font-size: 42px;
    font-weight: inherit;
    line-height: 35px;
}

.right_contaier .Employee {
    font-family: sans-serif;
    color: grey;
    margin-left: 251px;
    margin-top: 53px;
    font-weight: 100;
    font-size: 30px;
}

.right_contaier form {
    font-family: sans-serif;
    margin-left: 100px;
    margin-top: 40px;

}

.right_contaier select {
    width: 280px;
    height: 35px;
    font-size: 15px;
    border: 1px solid rgb(216, 206, 206);
    color: rgb(117 117 117);
}

.right_contaier input[type="text"],
[type="email"],
[type="password"],
[type="tel"] {
    width: 280px;
    height: 35px;
    font-size: 15px;
    text-indent: 10px;
    border: 1px solid rgb(216, 206, 206);
}

.right_contaier input[type="radio"] {
    accent-color: #3636b0;

}

.submit_btn {
    cursor: pointer;
    height: 35px;
    width: 190px;
    float: right;
    margin-right: 140px;
    border-radius: 20px;
    color: rgb(0, 0, 0);
    background-color: rgb(212, 194, 2);
    font-size: large;
    text-align: center;
    outline: none;
    border: none;
    transition: 1s ease;




}

.submit_btn:hover {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 0, 0);
    border: 1px solid rgb(255, 255, 255);

}

.pagination-container {
    width: calc(10% - 2rem);
    padding: 10px;
    display: flex;
    align-items: center;
    position: absolute;

    padding: 1rem 0;
    justify-content: center;
}

.pagination-number,
.pagination-button {
    font-size: 2.5rem;
    background-color: transparent;
    border: none;
    margin: 0.25rem 0.25rem;
    cursor: pointer;
    height: 2.9rem;
    width: 2.9rem;
    border-radius: .2rem;
}

.pagination-number:hover,
.pagination-button:not(.disabled):hover {
    background: yellow;
}

.pagination-number.active {
    color: #fff;
    background: red;
}












@media (min-width:100px) and (max-width:200px) {
    



    .main_container {
        width: 100%;
        display: grid;
        height: 1000px;
    }

    .left_container {
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }

    .left_container .korpaprikupljeno {

        padding: 3rem 0;
        display: flex;
        flex-direction: column;

    }


    .right_contaier {
        width: 186px;
        height: 400px;
        margin: 0px;
        float: unset;
        margin: 7px;
        border-radius: unset;
    }

    .right_contaier form {
        margin-left: 0px;
        margin-top: 0px;
    }

    .right_contaier .Employee {

        margin-left: 0px;
        margin-top: 0px;

        font-size: 20px;
    }

    .double_btn {
        position: unset;

        display: flex;
        width: 190px;

    }

    .right_contaier input[type="text"],
    [type="email"],
    [type="password"],
    [type="tel"] {
        width: 50px;
    }

    .right_contaier select {
        width: 50px;
    }

    .right_contaier i.fa-solid.fa-rocket.fa-flip-horizontal {

        font-size: 60px;
    }

    .right_contaier h1 {
        font-size: 30px;
        line-height: 15px
    }

    .right_contaier button {
        width: 130px;
    }

    .double_btn {

        width: 161px;
    }

    .first_btn,
    .second_btn {
        font-size: unset;
    }

    .left_container {
        width: 100%;
    }
}

@media (max-width: 650px) {


    .row {
        width: 100%;
    }

}


/* =================================================================
 My Media
*/
/* .d-none {
    display: none;
}

@media (max-width: 768px) {
    .d-none {
        display: block;
    }

    .main-nav li {
        display: none;
    }
} */


@media (max-width: 1250px) {
    .d-none{
        display: none;
    }
    #button{
        width:200px;
        height: 70px;
        font-size: 25px;
        right: 5%;
    }
    .main-navfixed{
        text-align: left;
    }
    
   
    .modalkorpa .kontent{
        width: 100%;
    }
    .hero-text-box {
        width: 100%;
    }

    .overlay {
        width: 240px;
    }

    .row .tekstonama {
        padding: 3rem;
    }

    .row {
        width: 100%;
    }

    .padd {
        padding: 3rem;
    }

    .section-plans {
        overflow: hidden;
    }

    .slikaartikal {
        margin: 0;
        width: 100%;
    }

    .porukakorpa p {
        font-size: 29px;
    }
    header {
        height: 60vh;
    }
}