.bianco {
  background-color: #f9f6f8;
}

.visone {
  background-color: #ca9e87;
}

.safari {
  background-color: #936653;
}

.beige {
  background-color: #e6bca8;
}

.daino {
  background-color: #906652;
}

.glace {
  background-color: #84614d;
}

.antilope {
  background-color: #694a40;
}

.bronzo {
  background-color: #6c4e44;
}

.gray {
  background-color: #6e6e6e;
}

.darkblue {
  background-color: #392e48;
}

.anthracite {
  background-color: #121212;
}

.nero {
  background-color: #000;
}

.playa {
  background-color: #ac9085;
}

.granat {
  background-color: #04063a;
}

.forest {
  background-color: #243e24;
}

.red {
  background-color: red;
}

.black {
  background-color: #000;
}

.ecru, .tabaco {
  background-color: #ecccb7;
}

.natural {
  background-color: #f2d8c8;
}

.grigio {
  background-color: #252525;
}

.pink {
  background-color: pink;
}

.white {
  background-color: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  color: #555;
  text-rendering: optimizelegibility;
  background-color: #fff;
  font-family: Raleway, sans-serif;
  font-size: 10px;
  font-weight: 300;
}

body {
  font-size: 2 rem;
}

.row {
  width: 1200px;
  margin: 0 auto;
}

section {
  padding: 8rem 0;
}

button, .btn {
  cursor: pointer;
}

.box {
  padding: 1%;
  font-size: 20px;
}

.celarfix:after {
  content: "";
  clear: both;
  display: block;
}

h1, h2, h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
}

h1 {
  color: #fff;
  word-spacing: 4px;
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 3rem;
  font-weight: 500;
}

h2:after {
  height: 2px;
  width: 8rem;
  content: "";
  background-color: #ff0;
  margin: 3rem auto;
  display: block;
}

h2 {
  word-spacing: 2rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 3.5rem;
}

h3 {
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
}

.artikal p {
  font-weight: 500;
}

header {
  height: 80vh;
  background-image: linear-gradient(#000000c5, #00000071), url("hero.7ff09c6b.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

header h1 span {
  color: #fff;
  font-size: 9rem;
  font-weight: 100;
}

.hero-text-box {
  width: 1140px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  height: 130px;
  width: auto;
  float: left;
  margin-top: 20px;
}

.main-nav {
  float: right;
  margin-top: 5rem;
  list-style: none;
}

.main-navfixed {
  float: none;
  text-align: center;
  z-index: 1000;
  width: 1200px;
  background: linear-gradient(to right, #b62c2c 15%, #e0d90a);
  border-radius: 10px;
  margin-top: 0;
  padding: 30px;
  position: fixed;
}

.main-nav li {
  margin-left: 4rem;
  display: inline-block;
}

.main-nav li :first-child {
  margin-left: 0;
}

.main-nav li a:link, .main-nav li a:visited {
  color: #fff;
  text-transform: uppercase;
  border-bottom: 2px solid #0000;
  padding-bottom: 8px;
  font-size: 2.4rem;
  text-decoration: none;
}

.main-nav li a:hover {
  border-bottom: 2px solid red;
  transition: all .5s;
}

.main-nav li a:active, .activelink {
  border-bottom: 2px solid #ff0;
  transition: all .5s;
}

.dropbtn {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 16px;
  font-size: 20px;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  min-width: 200px;
  z-index: 1;
  background-color: #404040c4;
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px #0003;
}

.dropdown-content a {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #eee70fa3;
  text-decoration: underline;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: #ff0;
}

.btn {
  color: #fff;
  background-color: red;
  border-radius: 200px;
  margin-top: 2rem;
  padding: 1rem 3rem;
  font-size: 2rem;
  text-decoration: none;
  transition: all .4s;
  display: inline-block;
}

.btn:active, .btn:hover {
  background-color: #ff0;
  transition: all .5s;
  color: red !important;
}

.btn-orange:link, .btn-orange:visited {
  color: #fff;
  background-color: red;
  border: 1px solid red;
  margin-right: 15px;
}

.btn-orange:active, .btn-orange:hover {
  color: #fff;
  background-color: red;
  border: 1px solid red;
  transition: all 1s;
}

.btn-outline:link, .btn-outline:visited {
  color: red;
  border: 1px solid red;
}

.btn-outline:active, .btn-outline:hover {
  color: #fff;
  border: 1px solid red;
}

.text-section-heading {
  width: 70%;
  text-align: center;
  margin: auto;
  font-size: 3rem;
  line-height: 2.9rem;
}

.box p {
  font-size: 2rem;
  line-height: 2.9rem;
  text-decoration: none;
}

.box a {
  color: gray;
  font-size: 1.8rem;
  text-decoration: none;
}

.box a:hover {
  color: #fff;
  background-color: #000;
  border-radius: 20px;
  padding: 10px;
  font-size: 2.5rem;
  transition: all 1s;
}

.tekstonama {
  width: 80%;
  text-align: center;
  cursor: pointer;
  width: auto;
  margin: auto;
  font-size: 3rem;
  line-height: 2.9rem;
}

.galerija {
  height: 500px;
  width: 100%;
  background-color: #000;
  border-radius: 20px;
}

.galerija img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.kontaktmreze, .slikaonama {
  justify-content: center;
  display: flex;
}

.icon-big {
  color: red;
  margin-bottom: 1rem;
  font-size: 6.5rem;
  display: block;
}

.icon-medium {
  color: gray;
  margin-bottom: 1rem;
  font-size: 4.5rem;
  display: block;
}

.icon-small {
  color: #ff0;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  margin-top: -5px;
  font-size: 2.4rem;
  line-height: 2.4rem;
  display: inline-block;
}

.section-features .text-section-heading {
  margin-bottom: 3rem;
}

.section-recomendation-meals {
  padding: 0;
}

.meals-showcase {
  width: 100%;
  margin: 0;
  list-style-type: none;
}

.meals-showcase li {
  float: left;
  width: 25%;
  border: 4px solid red;
  border-radius: 10px;
  list-style: none;
}

.meals-showcase li figure {
  width: 100%;
  background-color: #000;
  margin: 0;
  overflow: hidden;
}

.meals-showcase li figure img {
  width: 100%;
  height: 20rem;
  opacity: .7;
  transform: scale(1.15);
}

.meals-showcase li figure img:hover {
  opacity: 1;
  transition: all .5s, opacity .5s;
  transform: scale(1.03);
}

.overlay {
  height: 40px;
  width: 250px;
  opacity: 0;
  z-index: 5;
  background-color: #0009;
  border-radius: 10px;
  transition: all .5s;
  position: absolute;
}

.meals-showcase li figure:hover .overlay {
  opacity: 1;
}

.text {
  color: #fff;
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.searchK {
  width: 100%;
  margin-left: 43%;
}

.search-box {
  border: 5px solid #000;
  border-radius: 50px;
  justify-content: center;
  display: inline-block;
  position: relative;
}

.search-box input[type="text"] {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border: none;
  border-radius: 50px;
  padding: 5px 40px 5px 10px;
  font-family: Raleway, sans-serif;
  font-size: 20px;
  font-weight: bold;
  transition: width .8s cubic-bezier(.5, -.5, .5, .5) .6s;
}

.search-box input[type="text"]:focus {
  outline: none;
}

.search-box input[type="text"]:focus, .search-box input[type="text"]:not(:placeholder-shown) {
  width: 300px;
  transition: width .8s cubic-bezier(.5, -.5, .5, 1.5);
}

.search-box input[type="text"]:focus + span, .search-box input[type="text"]:not(:placeholder-shown) + span {
  transition: bottom .3s ease-out .8s, right .3s ease-out .8s;
  bottom: 13px;
  right: 10px;
}

.search-box input[type="text"]:focus + span:after, .search-box input[type="text"]:not(:placeholder-shown) + span:after {
  opacity: 1;
  transition: top .3s ease-out 1.1s, right .3s ease-out 1.1s, opacity .3s 1.1s;
  top: 0;
  right: 10px;
}

.search-box span {
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  transition: bottom .3s ease-out .3s, right .3s ease-out .3s;
  display: flex;
  position: absolute;
  bottom: -13px;
  right: -15px;
}

.search-box span:before, .search-box span:after {
  content: "";
  height: 25px;
  border-left: 5px solid #000;
  position: absolute;
  transform: rotate(-45deg);
}

.search-box span:after {
  opacity: 0;
  transition: top .3s ease-out, right .3s ease-out, opacity .3s ease-out;
  top: -20px;
  right: -10px;
  transform: rotate(45deg);
}

section {
  background-color: #f4f4f4;
}

#button {
  width: 150px;
  height: 50px;
  text-align: center;
  opacity: 0;
  z-index: 1000;
  color: #fff;
  background-color: red;
  border-radius: 40px;
  font-size: 20px;
  line-height: 30px;
  transition: background-color .3s;
  display: inline-block;
  position: fixed;
  bottom: 50px;
  right: 10%;
}

#button:hover {
  color: red;
  background-color: #ff0;
  border: 1px solid red;
  transition: all 1s;
}

.plan-box {
  width: 90%;
  background-color: #fff;
  border-radius: 8px;
  margin-left: 5%;
}

.plan-box div {
  border-bottom: 1px solid #e8e8e8;
  padding: 1.5rem;
  box-shadow: 1px 2px 4px #ddd;
}

.opisartikal {
  font-size: 2rem;
}

.velicinedostupne {
  justify-content: center;
  display: flex;
  width: auto !important;
}

.odabirvelicine {
  width: 70%;
  height: 60%;
  justify-content: center;
  display: flex;
}

.vel {
  width: 20%;
  height: 15%;
  border-radius: 10%;
  padding: .9rem;
}

.vel :hover {
  cursor: pointer;
  background-color: #ff0;
  transition: all 1s;
}

.velDodato {
  font-weight: bold;
  color: red !important;
}

.vel p {
  text-align: center;
  font-size: 2.3rem;
}

.odabirboje {
  height: 200px;
  justify-content: center;
}

.odabirboje .boja {
  width: 80px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  display: flex;
}

.bojaDodato {
  border: 7px solid #fff200;
}

.odabirkolicine {
  text-align: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
}

.odabirkolicine button {
  border-radius: 50%;
  padding: .8rem;
}

.odabirkolicine form input {
  width: 40%;
  border-radius: 40%;
  padding: 1rem;
}

.porukakorpa {
  height: 90%;
  margin-top: 1px;
}

.porukakorpa p {
  text-align: center;
  align-items: center;
  padding-top: 6px;
  font-size: 30px;
  font-weight: 900;
}

.korpaArtikal:hover {
  opacity: .2;
  cursor: pointer;
  background-image: url("kanta.c8dba4c6.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30%;
}

.kolicinaiporuka {
  height: 190px;
}

.plan-box div:last-child {
  text-align: center;
}

.divslika {
  height: 420px;
  justify-content: center;
  display: flex;
}

.slikaartikal {
  width: 80%;
  height: 370px;
  border-radius: 20px;
  margin-top: 5%;
  margin-left: 20%;
}

.slikauvecanamodal {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #0006;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.slikauvecana {
  width: 50%;
  height: 70%;
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 20%;
  justify-content: center;
  margin: 15% auto;
  padding: 5px;
  display: flex;
}

.modalporuka {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #0006;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.porukica {
  width: 500px;
  height: 300px;
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 20%;
  justify-content: center;
  align-items: center;
  margin: 15% auto;
  padding: 10px;
  font-size: 3rem;
  display: flex;
}

.modalporuka1 {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #0006;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.porukica1 {
  width: 500px;
  height: 300px;
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 20%;
  justify-content: center;
  align-items: center;
  margin: 15% auto;
  padding: 10px;
  font-size: 3rem;
  display: flex;
}

footer {
  background-color: #333;
  padding: 5rem;
}

.footer-nav {
  float: left;
  list-style: none;
}

.social-icons {
  float: right;
  list-style: none;
}

.footer-nav li, .social-icons li {
  margin-right: 2rem;
  display: inline-block;
}

.footer-nav li:last-child, .social-icons li:last-child {
  margin-right: 0;
}

.footer-nav li a:link, .footer-nav li a:visited, .social-icons li a:link, .social-icons li a:visited {
  color: #888;
  border: 0;
  font-size: 30px;
  text-decoration: none;
  transition: all 2s;
}

.footer-nav li a:active, .footer-nav li a:hover {
  color: #ddd;
  border-bottom: 1px solid #555;
}

footer p {
  color: #888;
  text-align: center;
  margin-top: 3rem;
  font-size: 20px;
}

.instagram:hover, .instagram:active {
  color: #c32aa3;
  font-size: 3.5rem;
  transition: all .5s;
  transform: rotate(30deg);
}

.facebook:hover, .facebook:active {
  color: #3b5998;
  font-size: 3.5rem;
  transition: all .5s;
  transform: rotate(30deg);
}

.twitter:hover, .twitter:active {
  color: #1da1f2;
  font-size: 3.5rem;
  transition: all .5s;
  transform: rotate(30deg);
}

.linkedin:hover, .linkedin:active {
  color: #007bb5;
  font-size: 3.5rem;
  transition: all .5s;
  transform: rotate(30deg);
}

.gmail:hover, .gmail:active {
  color: red;
  font-size: 3.5rem;
  transition: all .5s;
  transform: rotate(30deg);
}

.tiktok:hover, .tiktok:active {
  color: #000;
  font-size: 3.5rem;
  transition: all .5s;
  transform: rotate(30deg);
}

.hidden {
  display: none !important;
}

.modalkorpa {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #0006;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modalkorpa .kontent {
  width: 60%;
  background-color: #fefefe;
  border: 1px solid #888;
  justify-content: center;
  margin: 15% auto;
  padding: 10px;
  display: flex;
}

.main_container {
  width: 100%;
  height: 625px;
  background: linear-gradient(to right, #b62c2c 15%, #e0d90a);
}

.right_contaier {
  width: 800px;
  height: 490px;
  float: right;
  background-color: #f9f9fb;
  border-radius: 10% 0% 0% 10% / 45% 0% 0% 54%;
  margin: 50px;
}

.left_container {
  width: 200px;
  float: left;
  height: 400px;
  text-align: center;
  color: #fff;
  justify-content: center;
  margin-top: 30px;
  margin-left: 135px;
  font-family: sans-serif;
}

.korpaprikupljeno form input {
  text-align: center;
  width: 50px !important;
}

.korpaprikupljeno li {
  padding: 10px;
  border: 1px solid #fff !important;
}

.korpaprikupljeno {
  font-size: 2rem !important;
}

.modalkorpa i.fa-solid.fa-rocket.fa-flip-horizontal {
  font-size: 87px;
  transform: rotate(-45deg);
}

.h1 {
  font-size: 42px;
  font-weight: inherit;
  line-height: 35px;
}

.right_contaier .Employee {
  color: gray;
  margin-top: 53px;
  margin-left: 251px;
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 100;
}

.right_contaier form {
  margin-top: 40px;
  margin-left: 100px;
  font-family: sans-serif;
}

.right_contaier select {
  width: 280px;
  height: 35px;
  color: #757575;
  border: 1px solid #d8cece;
  font-size: 15px;
}

.right_contaier input[type="text"], [type="email"], [type="password"], [type="tel"] {
  width: 280px;
  height: 35px;
  text-indent: 10px;
  border: 1px solid #d8cece;
  font-size: 15px;
}

.right_contaier input[type="radio"] {
  accent-color: #3636b0;
}

.submit_btn {
  cursor: pointer;
  height: 35px;
  width: 190px;
  float: right;
  color: #000;
  text-align: center;
  background-color: #d4c202;
  border: none;
  border-radius: 20px;
  outline: none;
  margin-right: 140px;
  font-size: large;
  transition: all 1s;
}

.submit_btn:hover {
  color: #000;
  background-color: red;
  border: 1px solid #fff;
}

.pagination-container {
  width: calc(10% - 2rem);
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  display: flex;
  position: absolute;
}

.pagination-number, .pagination-button {
  cursor: pointer;
  height: 2.9rem;
  width: 2.9rem;
  background-color: #0000;
  border: none;
  border-radius: .2rem;
  margin: .25rem;
  font-size: 2.5rem;
}

.pagination-number:hover, .pagination-button:not(.disabled):hover {
  background: #ff0;
}

.pagination-number.active {
  color: #fff;
  background: red;
}

@media (min-width: 100px) and (max-width: 200px) {
  .main_container {
    width: 100%;
    height: 1000px;
    display: grid;
  }

  .left_container {
    justify-content: center;
    margin-left: 0;
    display: flex;
  }

  .left_container .korpaprikupljeno {
    flex-direction: column;
    padding: 3rem 0;
    display: flex;
  }

  .right_contaier {
    width: 186px;
    height: 400px;
    float: unset;
    border-radius: unset;
    margin: 7px;
  }

  .right_contaier form {
    margin-top: 0;
    margin-left: 0;
  }

  .right_contaier .Employee {
    margin-top: 0;
    margin-left: 0;
    font-size: 20px;
  }

  .double_btn {
    position: unset;
    width: 190px;
    display: flex;
  }

  .right_contaier input[type="text"], [type="email"], [type="password"], [type="tel"], .right_contaier select {
    width: 50px;
  }

  .right_contaier i.fa-solid.fa-rocket.fa-flip-horizontal {
    font-size: 60px;
  }

  .right_contaier h1 {
    font-size: 30px;
    line-height: 15px;
  }

  .right_contaier button {
    width: 130px;
  }

  .double_btn {
    width: 161px;
  }

  .first_btn, .second_btn {
    font-size: unset;
  }

  .left_container {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .row {
    width: 100%;
  }
}

@media (max-width: 1250px) {
  .d-none {
    display: none;
  }

  #button {
    width: 200px;
    height: 70px;
    font-size: 25px;
    right: 5%;
  }

  .main-navfixed {
    text-align: left;
  }

  .modalkorpa .kontent, .hero-text-box {
    width: 100%;
  }

  .overlay {
    width: 240px;
  }

  .row .tekstonama {
    padding: 3rem;
  }

  .row {
    width: 100%;
  }

  .padd {
    padding: 3rem;
  }

  .section-plans {
    overflow: hidden;
  }

  .slikaartikal {
    width: 100%;
    margin: 0;
  }

  .porukakorpa p {
    font-size: 29px;
  }

  header {
    height: 60vh;
  }
}

/*# sourceMappingURL=index.fc73018d.css.map */
